import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    DeleteMnemonicImage,
    GetMnemonic,
    GetMnemonicImages,
    InitDeviceOptions,
    InitVariableOptions,
    RedoCanvasState,
    SaveCanvasState,
    SetCurrentObject,
    SetIsEditing,
    SetMnemonicObjects,
    ToggleDevicePopup,
    ToggleImagePopup,
    ToggleSidebar,
    UndoCanvasState,
    UpdateCurrentObject,
    UpdateMnemonic,
    UpdateMnemonicData,
    UpdateMnemonicImageName,
    UpdateObjectValue,
    UploadMnemonicImages,
} from '../actions/mnemonic.actions';
import { ConstructorEditInterface, EditTypeInterface } from '../../_interfaces/constructor-edit.interface';
import { ConstructorEditTypeEnum } from '../../_enums/constructor-edit-type.enum';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { MnemonicInterface } from '../../_interfaces/mnemonic.interface';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { Variable } from '../../../app-shared-elements/_interfaces/Variable';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { CanvasEventHandlerService } from '../../_services/canvas-event-handler.service';
import { CreationType } from '../../../app-shared-elements/_enums/registrator-sync-status.enu';
import { AlarmTypeEnum } from '../../../events/_enums/alarm.enum';
import { MnemonicImageInterface } from '../../_interfaces/mnemonic-image.interface';
import { v4 as uuid } from 'uuid';
import { VariableTypeOptionsEnum } from '../../_enums/variable-type-options.enum';
import { ObjectTypeEnum } from '../../_enums/object-type.enum';
import { HttpClient } from '@angular/common/http';

export interface MnemonicStateModel {
    currentObject: any;
    mnemonic: MnemonicInterface;
    editConstructorArray: ConstructorEditInterface[];
    mnemonicImages: MnemonicImageInterface[];
    deviceOptions: SelectOptionInterface<string, string, Device>[];
    variableOptions: SelectOptionInterface<string, string, Variable>[];
    variableTypeOptions: SelectOptionInterface<VariableTypeOptionsEnum>[];
    isOpenDevicePopup: boolean;
    isOpenImagePopup: boolean;
    isEditing: boolean;
    mnemonicObjects: any[];
    canvasState: any[];
    redoCanvasState: any[];
    isShowSidebar: boolean;
}

const MNEMONIC_TOKEN = new StateToken<MnemonicStateModel>('mnemonic');

const variableTypeOptions: SelectOptionInterface<VariableTypeOptionsEnum>[] = [
    {
        key: VariableTypeOptionsEnum.name,
        type: 'text',
        value: 'table.chartSettings.tagName',
    },
    {
        key: VariableTypeOptionsEnum.value,
        type: 'text',
        value: 'mnemonic.tagValue',
    },
    {
        key: VariableTypeOptionsEnum.all,
        type: 'text',
        value: 'mnemonic.allTagValue',
    },
];

const initEditCanvasArray: ConstructorEditInterface[] = [
    {
        name: 'mnemonic.edit.image',
        type: ConstructorEditTypeEnum.backgroundImage,
        editType: EditTypeInterface.btn,
        objectType: null,
    },
    {
        name: 'mnemonic.edit.fontColor',
        type: ConstructorEditTypeEnum.overlayColor,
        editType: EditTypeInterface.input,
        isColorSelect: true,
        objectType: null,
    },
];

const initEditConstructorArray: ConstructorEditInterface[] = [
    {
        name: 'mnemonic.edit.image',
        type: ConstructorEditTypeEnum.backgroundImage,
        editType: EditTypeInterface.btn,
        objectType: null,
    },
    {
        name: 'mnemonic.edit.text',
        type: ConstructorEditTypeEnum.text,
        editType: EditTypeInterface.textArea,
        isColumn: true,
        placeholder: 'mnemonic.edit.placeholder.text',
        objectType: [ObjectTypeEnum.iText, ObjectTypeEnum.image],
    },
    {
        name: 'events.logicalEvents.table.tag',
        type: null,
        editType: EditTypeInterface.btn,
        objectType: [ObjectTypeEnum.text, ObjectTypeEnum.chart, ObjectTypeEnum.path],
    },
    {
        name: 'mnemonic.edit.isStatusColor',
        type: null,
        editType: EditTypeInterface.checkbox,
        objectType: ObjectTypeEnum.text,
    },
    {
        name: null,
        type: null,
        editType: EditTypeInterface.btn,
        objectType: [ObjectTypeEnum.image, ObjectTypeEnum.defaultImage],
    },
    {
        name: 'mnemonic.edit.tooltip',
        type: ConstructorEditTypeEnum.tooltip,
        editType: EditTypeInterface.textArea,
        isColumn: true,
        placeholder: 'mnemonic.edit.placeholder.tooltip',
        objectType: ObjectTypeEnum.all,
    },
    {
        name: null,
        type: ConstructorEditTypeEnum.settings,
        editType: null,
        objectType: ObjectTypeEnum.all,
        isFixedNumber: true,
    },
    {
        name: 'mnemonic.edit.position',
        type: ConstructorEditTypeEnum.position,
        editType: null,
        objectType: ObjectTypeEnum.all,
    },
    {
        name: 'mnemonic.edit.size',
        type: ConstructorEditTypeEnum.fontSize,
        editType: EditTypeInterface.input,
        isFixedNumber: true,
        objectType: [ObjectTypeEnum.text, ObjectTypeEnum.iText],
    },
    {
        name: null,
        type: ConstructorEditTypeEnum.textAlign,
        editType: null,
        objectType: [ObjectTypeEnum.text, ObjectTypeEnum.iText],
    },
    {
        name: 'mnemonic.edit.textColor',
        type: ConstructorEditTypeEnum.color,
        editType: EditTypeInterface.input,
        isColorSelect: true,
        objectType: [ObjectTypeEnum.text, ObjectTypeEnum.iText],
    },
    {
        name: 'mnemonic.edit.imageColor',
        type: ConstructorEditTypeEnum.color,
        editType: EditTypeInterface.input,
        isColorSelect: true,
        objectType: [ObjectTypeEnum.path],
    },
    {
        name: 'mnemonic.edit.fontColor',
        type: ConstructorEditTypeEnum.color,
        editType: EditTypeInterface.input,
        isColorSelect: true,
        objectType: [ObjectTypeEnum.group, ObjectTypeEnum.ellipse, ObjectTypeEnum.line],
    },
    {
        name: 'mnemonic.edit.borderColor',
        type: ConstructorEditTypeEnum.strokeColor,
        editType: EditTypeInterface.input,
        isColorSelect: true,
        objectType: [ObjectTypeEnum.circle, ObjectTypeEnum.rect, ObjectTypeEnum.ellipse],
    },
    {
        name: 'mnemonic.edit.fontColor',
        type: ConstructorEditTypeEnum.backgroundColor,
        editType: EditTypeInterface.input,
        isColorSelect: true,
        objectType: [ObjectTypeEnum.text, ObjectTypeEnum.iText, ObjectTypeEnum.rect],
    },
    {
        name: 'mnemonic.edit.opacity',
        type: ConstructorEditTypeEnum.opacity,
        editType: EditTypeInterface.input,
        objectType: [ObjectTypeEnum.rect, ObjectTypeEnum.ellipse],
        isFixedNumber: true,
    },
    {
        name: 'mnemonic.edit.angle',
        type: ConstructorEditTypeEnum.angle,
        editType: EditTypeInterface.input,
        objectType: ObjectTypeEnum.all,
        isFixedNumber: true,
    },
];

@State<MnemonicStateModel>({
    name: MNEMONIC_TOKEN,
    defaults: {
        currentObject: null,
        mnemonic: null,
        editConstructorArray: initEditConstructorArray,
        mnemonicImages: [],
        deviceOptions: [],
        variableOptions: [],
        isOpenDevicePopup: false,
        isOpenImagePopup: false,
        isEditing: false,
        variableTypeOptions,
        mnemonicObjects: [],
        canvasState: [],
        redoCanvasState: [],
        isShowSidebar: true,
    },
})
@Injectable()
export class MnemonicState {
    constructor(
        private http: HttpClient,
        private store: Store,
        private notificationsService: NotificationsService,
        private canvasEventHandlerService: CanvasEventHandlerService,
    ) {}

    @Selector()
    static getMnemonic(state: MnemonicStateModel): MnemonicInterface {
        return state.mnemonic;
    }

    @Selector()
    static getMnemonicImages(state: MnemonicStateModel): MnemonicImageInterface[] {
        return state.mnemonicImages;
    }

    @Selector()
    static getCurrentObject(state: MnemonicStateModel): any {
        return state.currentObject;
    }

    @Selector()
    static getDeviceOptions(state: MnemonicStateModel): SelectOptionInterface<string, string, Device>[] {
        return state.deviceOptions;
    }

    @Selector()
    static getVariableOptions(state: MnemonicStateModel): SelectOptionInterface<string, string, Variable>[] {
        return state.variableOptions;
    }

    @Selector()
    static getVariableTypeOptions(state: MnemonicStateModel): SelectOptionInterface<VariableTypeOptionsEnum>[] {
        return state.variableTypeOptions;
    }

    @Selector()
    static getIsOpenDevicePopup(state: MnemonicStateModel): boolean {
        return state.isOpenDevicePopup;
    }

    @Selector()
    static getIsOpenImagePopup(state: MnemonicStateModel): boolean {
        return state.isOpenImagePopup;
    }

    @Selector()
    static getIsEditing(state: MnemonicStateModel): boolean {
        return state.isEditing;
    }

    @Selector()
    static getCurrentDeviceOption(state: MnemonicStateModel): SelectOptionInterface<string, string, Device> {
        return state.deviceOptions.find((d) => d?.key === state.currentObject?.deviceId);
    }

    @Selector()
    static getCurrentVariableOption(state: MnemonicStateModel): SelectOptionInterface<string, string, Variable> {
        return state.variableOptions.find((v) => v.key === state.currentObject?.variableId);
    }

    @Selector()
    static getCurrentVariableTypeOption(state: MnemonicStateModel): SelectOptionInterface<VariableTypeOptionsEnum> {
        return state.variableTypeOptions.find((v) => v.key === state.currentObject?.selectOption);
    }

    @Selector()
    static getMnemonicObjects(state: MnemonicStateModel): any[] {
        return state.mnemonicObjects;
    }

    @Selector()
    static getEditConstructorArray(state: MnemonicStateModel): ConstructorEditInterface[] {
        if (state.currentObject.objectType) {
            return initEditConstructorArray.filter((item) => {
                if ((Array.isArray(item.objectType) ? item.objectType.includes(state.currentObject.objectType) : item.objectType === state.currentObject.objectType) || item.objectType === ObjectTypeEnum.all) {
                    return item;
                }
            });
        } else {
            return (state.editConstructorArray = initEditCanvasArray);
        }
    }

    @Selector()
    static getCanvasState(state: MnemonicStateModel): any[] {
        return state.canvasState;
    }

    @Selector()
    static getRedoCanvasState(state: MnemonicStateModel): any[] {
        return state.redoCanvasState;
    }

    @Selector()
    static getIsShowSidebar(state: MnemonicStateModel): boolean {
        return state.isShowSidebar;
    }

    @Action(SetCurrentObject)
    setCurrentObject(ctx: StateContext<MnemonicStateModel>, payload: SetCurrentObject): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentObject: payload.object,
        });
    }

    @Action(GetMnemonic)
    async getMnemonic(ctx: StateContext<MnemonicStateModel>, payload: GetMnemonic): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/virtual-devices/mnemonic', {
                params: {
                    registratorId: payload.registratorId,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                mnemonic: result.data,
            });
        }
    }

    @Action(UpdateMnemonicData)
    updateMnemonicData(ctx: StateContext<MnemonicStateModel>, payload: UpdateMnemonicData): void {
        const state = ctx.getState();
        this.canvasEventHandlerService.canvas.getObjects().forEach((obj: any) => {
            payload.devices.forEach((device) => {
                const currentVariable = device.variables.find((variable) => variable.originVariableId === obj.variableId);
                if (!currentVariable || !obj.variableId || (obj.objectType !== ObjectTypeEnum.text && obj.objectType !== ObjectTypeEnum.path)) {
                    return;
                }

                if (obj.objectType === ObjectTypeEnum.text) {
                    switch (obj.selectOption) {
                        case VariableTypeOptionsEnum.all:
                            obj.text = (currentVariable.customName ?? currentVariable.name) + ' ' + (currentVariable.unitName ? currentVariable.currentValue + currentVariable.unitName : currentVariable.currentValue);
                            break;
                        case VariableTypeOptionsEnum.name:
                            obj.text = currentVariable.customName ?? currentVariable.name;
                            break;
                        case VariableTypeOptionsEnum.value:
                            obj.text = currentVariable.unitName ? currentVariable.currentValue + currentVariable.unitName : currentVariable.currentValue;
                            break;
                    }

                    if (obj.isStatusColor) {
                        switch (currentVariable.status) {
                            case AlarmTypeEnum.ok:
                                obj.fill = '#0FA579';
                                break;
                            case AlarmTypeEnum.alarm:
                                obj.fill = '#FF6161';
                                break;
                            case AlarmTypeEnum.attention:
                                obj.fill = '#f5a623';
                                break;
                        }
                    }
                    this.canvasEventHandlerService.canvas.renderAll();
                    return;
                }

                if (obj && obj.type === ObjectTypeEnum.group && obj._objects) {
                    obj._objects.forEach((item) => {
                        switch (currentVariable.status) {
                            case AlarmTypeEnum.ok:
                                item.fill = '#0FA579';
                                item.stroke = '#0FA579';
                                break;
                            case AlarmTypeEnum.alarm:
                                item.fill = '#FF6161';
                                item.stroke = '#FF6161';
                                break;
                            case AlarmTypeEnum.attention:
                                item.fill = '#f5a623';
                                item.stroke = '#f5a623';
                                break;
                        }
                    });

                    this.canvasEventHandlerService.canvas.renderAll();
                }
                switch (currentVariable.status) {
                    case AlarmTypeEnum.ok:
                        obj.fill = '#0FA579';
                        break;
                    case AlarmTypeEnum.alarm:
                        obj.fill = '#FF6161';
                        break;
                    case AlarmTypeEnum.attention:
                        obj.fill = '#f5a623';
                        break;
                }
            });
        });
        this.canvasEventHandlerService.canvas.renderAll();
    }

    @Action(UpdateMnemonic)
    async updateMnemonic(ctx: StateContext<MnemonicStateModel>, payload: UpdateMnemonic): Promise<void> {
        const result: ApiResponse = (await this.http
            .put('/api/virtual-devices/mnemonic', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(GetMnemonicImages)
    async getMnemonicImages(ctx: StateContext<MnemonicStateModel>, payload: GetMnemonicImages): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/virtual-devices/mnemonic-files', {
                params: {
                    registratorId: payload.registratorId,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                mnemonicImages: result.data,
            });
        }
    }

    @Action(UploadMnemonicImages)
    async uploadMnemonicImages(ctx: StateContext<MnemonicStateModel>, payload: UploadMnemonicImages): Promise<void> {
        const formData = new FormData();
        formData.append('filename', payload.files[0]);
        const result: ApiResponse = (await this.http
            .post('/api/virtual-devices/mnemonic-files', formData, {
                params: {
                    registratorId: payload.registratorId,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                mnemonicImages: [result.data, ...state.mnemonicImages],
            });

            ctx.dispatch(new GetMnemonicImages(payload.registratorId));
        }
    }

    @Action(UpdateMnemonicImageName)
    async updateMnemonicImageName(ctx: StateContext<MnemonicStateModel>, payload: UpdateMnemonicImageName): Promise<void> {
        const result: ApiResponse = (await this.http
            .put('/api/virtual-devices/mnemonic-files', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(DeleteMnemonicImage)
    async deleteMnemonicImage(ctx: StateContext<MnemonicStateModel>, payload: DeleteMnemonicImage): Promise<void> {
        const result: ApiResponse = (await this.http
            .delete('/api/virtual-devices/mnemonic-file', {
                params: {
                    id: payload.id,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                mnemonicImages: state.mnemonicImages.filter((f) => f.id !== payload.id),
            });
        }
    }

    @Action(InitDeviceOptions)
    initDeviceOptions(ctx: StateContext<MnemonicStateModel>): void {
        const state = ctx.getState();

        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const deviceOptions = [];

        const registrators = this.store.selectSnapshot(DevicesState.getOwnerRegistrators).sort((a, b) => (a.name > b.name ? 1 : -1));

        registrators.forEach((r) => {
            const devicesByRegistrator: Device[] = devices.filter((d) => d.registratorId === r.id).sort((a, b) => (a.type > b.type ? 1 : -1));

            deviceOptions.push({
                key: r.id,
                value: r.name || r.defaultName,
                property: r,
                postIconPath: `./assets/design/icons/custom-select/registrator${r.creationType === CreationType.ORIGIN ? '' : '-virtual'}.svg`,
            });

            devicesByRegistrator.forEach((d) => {
                deviceOptions.push({
                    key: d.id,
                    value: d.name || d.defaultName,
                    property: d,
                    postIconPath: `./assets/design/icons/custom-select/${d.type}.svg`,
                });
            });
        });

        ctx.setState({
            ...state,
            deviceOptions,
        });
    }

    @Action(InitVariableOptions)
    initVariableOptions(ctx: StateContext<MnemonicStateModel>, payload: InitVariableOptions): void {
        const state = ctx.getState();

        const currentDevice = this.store.selectSnapshot(DevicesState.getDevices).find((d) => d.id === payload.currentDevice.key);

        ctx.setState({
            ...state,
            variableOptions: currentDevice.variables
                .map((variable) => {
                    return {
                        key: variable.id,
                        type: 'text',
                        value: variable.customName ?? variable.name,
                        property: variable,
                    };
                })
                .sort((a, b) => (a.value > b.value ? 1 : -1)),
        });
    }

    @Action(ToggleDevicePopup)
    toggleDevicePopup(ctx: StateContext<MnemonicStateModel>, payload: ToggleDevicePopup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isOpenDevicePopup: payload.toggle,
        });
    }

    @Action(ToggleImagePopup)
    toggleImagePopup(ctx: StateContext<MnemonicStateModel>, payload: ToggleDevicePopup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isOpenImagePopup: payload.toggle,
        });
    }

    @Action(SetIsEditing)
    setIsEditing(ctx: StateContext<MnemonicStateModel>, payload: SetIsEditing): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isEditing: payload.isEdit,
        });
    }

    @Action(SetMnemonicObjects)
    setMnemonicObjects(ctx: StateContext<MnemonicStateModel>, payload: SetMnemonicObjects): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            mnemonicObjects: payload.objects,
        });
    }

    @Action(UpdateCurrentObject)
    updateCurrentObject(ctx: StateContext<MnemonicStateModel>, payload: UpdateCurrentObject): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentObject: payload.object,
        });
    }

    @Action(UpdateObjectValue)
    async updateObjectValue(ctx: StateContext<MnemonicStateModel>, payload: UpdateObjectValue): Promise<void> {
        if (!payload.isNotSaveState) {
            await ctx.dispatch(new SaveCanvasState(this.canvasEventHandlerService.canvas.toJSON())).toPromise();
        }
        const state = ctx.getState();

        await this.canvasEventHandlerService.canvas.getActiveObject().set({
            ...payload.data,
        });

        await this.canvasEventHandlerService.canvas.renderAll();

        ctx.setState({
            ...state,
        });
    }

    @Action(SaveCanvasState)
    async saveCanvasState(ctx: StateContext<MnemonicStateModel>, payload: SaveCanvasState): Promise<void> {
        let data = payload.state;

        data = {
            ...data,
            id: uuid(),
            objects: data.objects.map((item) => {
                const currentObj = (this.canvasEventHandlerService.canvas as any).getObjects().find((f) => f.id === item.id);

                if (item.type === ObjectTypeEnum.chart) {
                    return {
                        ...item,
                        deviceId: currentObj?.deviceId,
                        variableId: currentObj?.variableId,
                        selectOption: currentObj?.selectOption,
                        name: currentObj?.name,
                        chart: {
                            ...currentObj.chart,
                            data: {
                                ...currentObj?.chart?.data,
                                datasets: currentObj.chart?.data?.datasets?.map((dataset) => {
                                    return {
                                        data: [...dataset.data],
                                        label: dataset.label,
                                    };
                                }),
                            },
                        },
                    };
                }

                if (item.type === ObjectTypeEnum.image || item.type === ObjectTypeEnum.group) {
                    return {
                        ...item,
                        name: currentObj?.name,
                        text: currentObj?.text,
                        tooltip: currentObj?.tooltip,
                    };
                }

                return {
                    ...item,
                    ...currentObj,
                };
            }),
        };

        const state = ctx.getState();

        ctx.setState({
            ...state,
            canvasState: [data, ...state.canvasState],
        });

        if (state.canvasState.length >= 20) {
            ctx.setState({
                ...state,
                canvasState: state.canvasState.filter((f) => f.id !== state.canvasState[state.canvasState.length - 1].id),
            });
        }
    }

    @Action(UndoCanvasState)
    async undoCanvasState(ctx: StateContext<MnemonicStateModel>): Promise<void> {
        const state = ctx.getState();

        const firstState = state.canvasState[0];
        if (!firstState) {
            return;
        }

        ctx.setState({
            ...state,
            redoCanvasState: [firstState, ...state.redoCanvasState],
            canvasState: state.canvasState.filter((f) => f.id !== firstState.id),
        });

        this.canvasEventHandlerService.canvas.clear();
        this.canvasEventHandlerService.canvas.renderAll();
        await this.canvasEventHandlerService.canvas.loadFromJSON(firstState, this.canvasEventHandlerService.canvas.renderAll.bind(this.canvasEventHandlerService.canvas));
    }

    @Action(RedoCanvasState)
    async redoCanvasState(ctx: StateContext<MnemonicStateModel>): Promise<void> {
        const state = ctx.getState();

        const firstState = state.redoCanvasState[state.redoCanvasState.length - 1];

        if (!firstState) {
            return;
        }

        ctx.setState({
            ...state,
            redoCanvasState: state.redoCanvasState.filter((f) => f.id !== firstState.id),
        });

        this.canvasEventHandlerService.canvas.clear();
        this.canvasEventHandlerService.canvas.renderAll();
        await this.canvasEventHandlerService.canvas.loadFromJSON(firstState, this.canvasEventHandlerService.canvas.renderAll.bind(this.canvasEventHandlerService.canvas));
    }

    @Action(ToggleSidebar)
    toggleSidebar(ctx: StateContext<MnemonicStateModel>, payload: ToggleSidebar): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isShowSidebar: payload.toggle,
        });
    }
}
