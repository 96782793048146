import { v4 as uuid } from 'uuid';
import { Injectable } from '@angular/core';
import { CustomFabricEllipse, CustomFabricIText, CustomFabricRect, CustomFabricText, Pointer } from '../_data/data';
import { DrawingToolsIconsEnum } from '../_enums/drawing-tools-icons.enum';
import { ObjectTypeEnum } from '../_enums/object-type.enum';
import { ThemeEnum } from '../../theme/_enums/theme.enum';
import { Canvas, Ellipse, FabricText, Group, IText, Line, loadSVGFromURL, Rect, Triangle, util } from 'fabric';

@Injectable({
    providedIn: 'root',
})
export class FabricShapeService {
    createEllipse(canvas: Canvas, pointer: Pointer, theme: ThemeEnum): CustomFabricEllipse {
        const ellipse = new Ellipse({
            left: pointer.x,
            top: pointer.y,
            stroke: theme === ThemeEnum.light ? '#333' : '#fff',
            fill: '',
            originX: 'center',
            originY: 'center',
            rx: 100,
            ry: 100,
            width: 100,
            height: 100,
            selectable: true,
            hasRotatingPoint: false,
            backgroundColor: 'transparent',
            tooltip: null,
            padding: 8,
            objectType: ObjectTypeEnum.ellipse,
            cornerColor: '#3e96fa',
            name: this.getToolsName('ellipse', canvas),
        } as CustomFabricRect) as CustomFabricEllipse;
        ellipse.id = uuid();
        canvas.add(ellipse);
        return ellipse;
    }

    createRectangle(canvas: Canvas, pointer: Pointer, theme: ThemeEnum): CustomFabricRect {
        const rect = new Rect({
            left: pointer.x,
            top: pointer.y,
            stroke: theme === ThemeEnum.light ? '#333' : '#fff',
            fill: null,
            originX: 'center',
            originY: 'center',
            width: 100,
            height: 100,
            selectable: true,
            hasRotatingPoint: false,
            backgroundColor: 'transparent',
            tooltip: null,
            padding: 8,
            cornerColor: '#3e96fa',
            type: ObjectTypeEnum.rect,
            objectType: ObjectTypeEnum.rect,
            name: this.getToolsName('rectangle', canvas),
        } as CustomFabricRect) as CustomFabricRect;
        rect.id = uuid();
        canvas.add(rect);
        return rect;
    }

    createDoubleTriangle(canvas: Canvas, pointer: Pointer, theme: ThemeEnum): any {
        const triangle = new Triangle({
            width: 10,
            height: 15,
            fill: theme === ThemeEnum.light ? '#333' : '#fff',
            left: pointer.x + 165,
            top: pointer.y - 4,
            angle: 90,
        });

        const doubleTriangle = new Triangle({
            width: 10,
            height: 15,
            fill: theme === ThemeEnum.light ? '#333' : '#fff',
            left: pointer.x - 15,
            top: pointer.y + 7,
            angle: -90,
        });

        const line = new Line([50, 100, 200, 100], {
            stroke: theme === ThemeEnum.light ? '#333' : '#fff',
            fill: theme === ThemeEnum.light ? '#333' : '#fff',
            strokeWidth: 3,
            left: pointer.x,
            top: pointer.y,
            selectable: false,
            hasRotatingPoint: false,
        });

        const objs = [line, triangle, doubleTriangle];

        const arrow = new Group(objs);

        arrow.set({
            angle: 0,
            id: uuid(),
            padding: 8,
            originX: 'center',
            originY: 'center',
            cornerColor: '#3e96fa',
            name: this.getToolsName('arrow', canvas),
            objectType: ObjectTypeEnum.group,
            type: ObjectTypeEnum.group,
        });
        canvas.add(arrow);

        return arrow;
    }

    createTriangle(canvas: Canvas, pointer: Pointer, theme: ThemeEnum): any {
        const triangle = new Triangle({
            width: 10,
            height: 15,
            fill: theme === ThemeEnum.light ? '#333' : '#fff',
            left: pointer.x + 165,
            top: pointer.y - 4,
            angle: 90,
        });

        const line = new Line([50, 100, 200, 100], {
            stroke: theme === ThemeEnum.light ? '#333' : '#fff',
            fill: theme === ThemeEnum.light ? '#333' : '#fff',
            strokeWidth: 3,
            left: pointer.x,
            top: pointer.y,
            selectable: false,
            hasRotatingPoint: false,
        });

        const objs = [line, triangle];

        const arrow = new Group(objs);

        arrow.set({
            angle: 0,
            id: uuid(),
            padding: 8,
            originX: 'center',
            originY: 'center',
            cornerColor: '#3e96fa',
            name: this.getToolsName('arrow', canvas),
            objectType: ObjectTypeEnum.group,
            type: ObjectTypeEnum.group,
        });

        canvas.add(arrow);

        return arrow;
    }

    createLine(canvas: Canvas, pointer: Pointer, theme: ThemeEnum): any {
        const line = new Line([50, 100, 200, 100], {
            stroke: theme === ThemeEnum.light ? '#333' : '#fff',
            fill: theme === ThemeEnum.light ? '#333' : '#fff',
            strokeWidth: 3,
            left: pointer.x,
            top: pointer.y,
            selectable: false,
            hasRotatingPoint: false,
            tooltip: null,
            id: uuid(),
            angle: 0,
            padding: 8,
            originX: 'center',
            originY: 'center',
            cornerColor: '#3e96fa',
            name: this.getToolsName('line', canvas),
            objectType: ObjectTypeEnum.line,
            type: ObjectTypeEnum.line,
        } as any);

        canvas.add(line);
    }

    createImage(canvas: Canvas, pointer: Pointer, theme: ThemeEnum, image?: DrawingToolsIconsEnum): any {
        if (image !== DrawingToolsIconsEnum.defaultImage) {
            return loadSVGFromURL(image, (objects: any, options) => {
                const img: any = util.groupSVGElements(objects, options);
                (img as any).set({
                    top: pointer.y,
                    left: pointer.x,
                    name: this.getToolsName('path', canvas),
                    tooltip: null,
                    objectType: ObjectTypeEnum.path,
                    type: img._objects?.length ? ObjectTypeEnum.group : ObjectTypeEnum.path,
                    id: uuid(),
                    originX: 'center',
                    originY: 'center',
                    src: image,
                    padding: 8,
                    cornerColor: '#3e96fa',
                });

                img.setCoords();
                canvas.calcOffset();
                canvas.add(img);
                canvas.renderAll();
            });
        } else {
            // return FabricImage.fromURL(image, (img: any) => {
            //     (img as any).scale(1).set({
            //         top: pointer.y,
            //         left: pointer.x,
            //         name: this.getToolsName('image', canvas),
            //         tooltip: null,
            //         objectType: image === DrawingToolsIconsEnum.defaultImage ? ObjectTypeEnum.defaultImage : ObjectTypeEnum.image,
            //         id: uuid(),
            //         originX: 'center',
            //         originY: 'center',
            //         type: ObjectTypeEnum.image,
            //         src: image,
            //         padding: 8,
            //         cornerColor: '#3e96fa',
            //     });
            //
            //     canvas.renderAll();
            //
            //     canvas.add(img);
            // });
        }
    }

    createIText(
        canvas: Canvas,
        opts: {
            content?: string;
            pointer: { x: number; y: number };
        },
        theme: ThemeEnum,
    ): CustomFabricIText {
        const iText = new IText(opts.content ?? this.getToolsName('text', canvas), {
            fill: theme === ThemeEnum.light ? '#333' : '#fff',
            fontSize: 20,
            backgroundColor: '',
            left: opts.pointer.x,
            top: opts.pointer.y,
            selectable: true,
            hasRotatingPoint: false,
            angle: 0,
            originX: 'center',
            originY: 'center',
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            lineHeight: 1.16,
            tooltip: null,
            textAlign: 'center',
            type: ObjectTypeEnum.iText,
            objectType: ObjectTypeEnum.iText,
            name: this.getToolsName('text', canvas),
            padding: 8,
            cornerColor: '#3e96fa',
        } as CustomFabricIText).scale(1) as CustomFabricIText;
        iText.id = uuid();
        canvas.add(iText);
        return iText;
    }

    createText(
        canvas: Canvas,
        opts: {
            content?: string;
            pointer: { x: number; y: number };
        },
        theme: ThemeEnum,
    ): CustomFabricText {
        const Text = new FabricText(opts.content ?? this.getToolsName('value', canvas), {
            stroke: null,
            fill: theme === ThemeEnum.light ? '#333' : '#fff',
            fontSize: 20,
            left: opts.pointer.x,
            top: opts.pointer.y,
            originX: 'center',
            originY: 'center',
            selectable: true,
            hasRotatingPoint: false,
            backgroundColor: '',
            angle: 0,
            id: uuid(),
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            lineHeight: 1.16,
            isStatusColor: true,
            tooltip: '',
            textAlign: 'center',
            type: ObjectTypeEnum.text,
            objectType: ObjectTypeEnum.text,
            name: this.getToolsName('value', canvas),
            padding: 8,
            cornerColor: '#3e96fa',
        } as CustomFabricText & { isStatusColor: boolean }) as CustomFabricText;
        canvas.add(Text);
        return Text;
    }

    getToolsName(name: string, canvas: Canvas): string {
        return name + canvas?.getObjects()?.filter((f: any) => f.name?.includes(name))?.length;
    }
}
